import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'

import { SHOP_PATH } from '@libs-components/utils/routes'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <div className='m-auto bg-white'>
      <div className='text-grayscale-800 m-auto flex h-[calc(100vh-90px)] w-full max-w-[800px] flex-col items-center justify-center px-5'>
        <Image
          src='https://public.wordup.com.tw/shop/assets/404_page_kv_D.png'
          alt='not found'
          width={600}
          height={230}
          className='h-auto min-w-full max-w-full'
        />
        <h1 className='text-center text-xl font-medium'>{t('404.title')}</h1>
        <h2 className='text-center text-sm font-normal'>{t('404.message')}</h2>

        <Link
          href={SHOP_PATH.HOME()}
          className='no-text-decoration rounded-lg bg-cyan-500 px-4 py-1 text-center leading-[40px] text-white no-underline'
        >
          {t('common.to_homepage')}
        </Link>
      </div>
    </div>
  )
}

export default NotFoundPage

export const getStaticProps = async ({ locale }: { locale: string }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}
